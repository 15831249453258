import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../context/state";
import Container from "./Container";

import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { FiChevronUp } from "react-icons/fi";

function Footer() {
  const [appData] = useAppContext();
  const loc = useLocation();

  return (
    <div className="shrink-0">
      <Container className="py-12">
        <div className="flex flex-col md:flex-row items-center">
          <div className="shrink-0 mb-4 sm:mb-8 md:mb-0">
            <img
              src="/images/sencam.svg"
              className="w-40 h-auto grayscale opacity-60"
              alt="Şencam"
            />
          </div>
          <div className="flex-grow flex flex-col sm:flex-row justify-end items-center gap-4 sm:gap-8 font-heading mb-4 md:mb-0">
            {/* <Link to="/privacy-policy" className="hover:underline underline-offset-2">{appData.translations.i18n.privacy_policy.toLocaleUpperCase(appData.translations.locale)}</Link>
            <Link to="/terms" className="hover:underline underline-offset-2">{appData.translations.i18n.terms_of_use.toLocaleUpperCase(appData.translations.locale)}</Link> */}
            <Link
              to="/team"
              className={
                "hover:underline underline-offset-2" +
                (loc.pathname.indexOf("/team") === 0 ? " underline" : "")
              }
            >
              {appData.translations.i18n.our_team.toLocaleUpperCase(
                appData.translations.locale
              )}
            </Link>
            <Link
              to="/brands"
              className={
                "hover:underline underline-offset-2" +
                (loc.pathname.indexOf("/brands") === 0 ? " underline" : "")
              }
            >
              {appData.translations.i18n.our_brands.toLocaleUpperCase(
                appData.translations.locale
              )}
            </Link>
            <Link
              to="/career"
              className={
                "hover:underline underline-offset-2" +
                (loc.pathname.indexOf("/career") === 0 ? " underline" : "")
              }
            >
              {appData.translations.i18n.career.toLocaleUpperCase(
                appData.translations.locale
              )}
            </Link>
            <Link
              to="/gallery"
              className={
                "hover:underline underline-offset-2" +
                (loc.pathname.indexOf("/gallery") === 0 ? " underline" : "")
              }
            >
              {appData.translations.i18n.gallery.toLocaleUpperCase(
                appData.translations.locale
              )}
            </Link>
            <Link
              to="/certificates"
              className={
                "hover:underline underline-offset-2" +
                (loc.pathname.indexOf("/certificates") === 0
                  ? " underline"
                  : "")
              }
            >
              {appData.translations.i18n.certificates.toLocaleUpperCase(
                appData.translations.locale
              )}
            </Link>
          </div>
          <div className="shrink-0 ml-12 hidden lg:block">
            <div
              className="btn flex items-center justify-center w-14 h-14 border border-white border-opacity-10 select-none cursor-pointer hover:border-opacity-20 transition-all"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <FiChevronUp size="24" className="animate-tarrow" />
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-5 mt-4 sm:mt-6">
          <div className="shrink-0 flex items-center sm:-ml-3">
            <a
              href="https://twitter.com/sencamsan"
              target="_blank"
              rel="noreferrer"
              className="text-white opacity-30 hover:opacity-100 transition-opacity px-3 py-2"
            >
              <FaTwitter />
            </a>
            <a
              href="https://facebook.com/sencamsan"
              target="_blank"
              rel="noreferrer"
              className="text-white opacity-30 hover:opacity-100 transition-opacity px-3 py-2"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://instagram.com/sencamsan"
              target="_blank"
              rel="noreferrer"
              className="text-white opacity-30 hover:opacity-100 transition-opacity px-3 py-2"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="flex-grow hidden sm:block">
            <hr className="border-white border-opacity-10" />
          </div>
          <div
            className="shrink-0 text-sm"
            dangerouslySetInnerHTML={{
              __html: appData.translations.i18n.copyright_text,
            }}
          />
        </div>

        <div className="text-center text-sm italic text-gray-400 pt-2">
          {appData.translations.i18n.trademark_warning}
        </div>
      </Container>
    </div>
  );
}

export default Footer;
