import { Link } from "react-router-dom";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Heading from "../components/Heading";
import { useAppContext } from "../context/state";

import { BsDownload, BsEye } from "react-icons/bs";

function Certificates() {
  const [appData] = useAppContext();

  return (
    <>
      <Header blur="none" />
      <div className="relative min-h-screen overflow-hidden">
        <div className="flex flex-col relative z-30 pt-20 min-h-screen">
          <Container className="flex-grow flex items-center justify-center">
            <div className="w-full py-16">
              <Heading>
                {appData.translations.i18n.certificates.toLocaleUpperCase(
                  appData.translations.locale
                )}
              </Heading>
              <div className="flex flex-col md:flex-row items-stretch w-full md:w-11/12 mx-auto border border-white border-opacity-25">
                <div className="w-full md:w-1/2 py-28 md:py-16 px-6 md:px-0 shrink-0 bg-white bg-opacity-5 flex flex-col items-center justify-center">
                  <div className="mb-2 text-center text-4xl md:text-5xl font-heading">
                    {appData.translations.i18n.sencam_certificates.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  </div>
                  <div className="mb-6 text-center text-gray-400">
                    {appData.translations.i18n.certificates_desc_text}
                  </div>
                  <div className="flex items-center justify-center gap-4">
                    <a
                      href="/files/sencam-iso.pdf?v=1.2"
                      download
                      target="_self"
                      className="btn flex items-center justify-center w-14 h-14 border border-white border-opacity-10 select-none cursor-pointer hover:border-opacity-20 transition-all"
                    >
                      <BsDownload size="24" />
                    </a>
                    <Link
                      to="/files/sencam-iso.pdf?v=1.2"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="btn flex items-center justify-center w-14 h-14 border border-white border-opacity-10 select-none cursor-pointer hover:border-opacity-20 transition-all"
                    >
                      <BsEye size="24" />
                    </Link>
                  </div>
                </div>
                <div className="w-full md:w-1/2 shrink-0 h-96 md:h-[465px]">
                  <img
                    src="/images/sencam-certificates.png"
                    className="w-full h-full object-cover"
                    alt={appData.translations.i18n.sencam_certificates.toLocaleUpperCase(
                      appData.translations.locale
                    )}
                  />
                </div>
              </div>
            </div>
          </Container>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Certificates;
