import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { AppWrapper } from "./context/state";
import About from "./pages/About";
import Brands from "./pages/Brands";
import Career from "./pages/Career";
import Catalog from "./pages/Catalog";
import Certificates from "./pages/Certificates";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";
import Gallery from "./pages/Gallery";
import GalleryCategory from "./pages/GalleryCategory";
import Home from "./pages/Home";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import Product from "./pages/Product";
import Products from "./pages/Products";
import ProductsCategory from "./pages/ProductsCategory";
import References from "./pages/References";
import Team from "./pages/Team";
import ScrollToTop from "./snippets/ScrollToTop";

function App() {
  return (
    <AppWrapper>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/references" element={<References />} />
          <Route path="/references/p/:page" element={<References />} />
          <Route path="/products" element={<ProductsCategory />} />
          <Route path="/products/:cat" element={<Products />} />
          <Route path="/products/:cat/p/:page" element={<Products />} />
          <Route path="/products/:cat/:id" element={<Product />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/p/:page" element={<News />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="/catalog" element={<Catalog />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/brands/p/:page" element={<Brands />} />
          <Route path="/team" element={<Team />} />
          <Route path="/gallery" element={<GalleryCategory />} />
          <Route path="/gallery/:cat" element={<Gallery />} />
          <Route path="/gallery/:cat/p/:page" element={<Gallery />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </AppWrapper>
  );
}

export default App;
